@font-face {
    font-family: "Helvetica Medium";
    src: url("../fonts/HelveticaNeueLTStd-Md.otf");
    font-display: swap;
    }
    @font-face {
    font-family: "Helvetica Normal";
    src: url("../fonts/HelveticaNeueLTStd-Lt.otf");
    font-display: swap;
    }
    @font-face {
    font-family: "Helvetica Bold";
    src: url("../fonts/HelveticaNeueLTStd-Bd.otf");
    font-display: swap;
    }
    @font-face {
    font-family: "Helvetica Heavy";
    src: url("../fonts/HelveticaNeueLTStd-Hv.otf");
    font-display: swap;
    }
    @font-face {
        font-family: 'Bebas Neue';
        src: url('../fonts/BebasNeue-Regular.otf');
        font-display: swap;
    }
    @import '../../node_modules/react-modal-video/scss/modal-video.scss';
        
    
    * {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;
    }
    body{
    font-family: 'Helvetica Medium', sans-serif;
    margin: 0 auto;
    width: 100%;
    min-width: 330px;
        picture{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
    .responsiva{
        width: 100%;
        max-width: 100%;
        height: auto;
    }
    
    #root{
        width: 100%;
        max-width: 1920px;
        margin: 0;
    }
    .App{
        width: 100%;
        max-width: 1920px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: flex-start;
        position: relative;
        .header{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-content: center;
            position: relative;
            a {
                cursor: pointer;
            }
        }
        .grid{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-content: flex-start;
            margin-top:80px;
            .item{
                width: 50%;
                position: relative;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                .text{
                    position: absolute;
                    z-index: 1;
                    top: 10%;
                    left: 20%;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-content: center;
                    color: #ffffff;
                    .title{
                        width: 100%;
                        font-size: 7rem;
                        margin: 20px 0;
                        color:#0097AA;
                        font-family: 'Bebas Neue', sans-serif;
                        text-align: start;
                        line-height: 90px;
                    }
                    .texto{
                        width: 100%;
                        font-size: 2rem;
                        text-align: start;
                        color:#0097AA;
                        font-family: 'Helvetica Medium', sans-serif;
                        margin-top: 20px;
                    }                    
                    .textoBlanco {
                        width: 100%;
                        font-size: 2rem;
                        text-align: start;
                        color:#fff;
                        font-family: 'Helvetica Medium', sans-serif;
                    }
                }
                .text-encuentra{
                    position: absolute;
                    z-index: 1;
                    padding-left: 15%;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-content: center;
                    color: #ffffff;                  
                    .textoBlanco {
                        width: 100%;
                        font-size: 2rem;
                        text-align: start;
                        color:#fff;
                        font-family: 'Helvetica', sans-serif;
                        line-height: 45px;
                    }
                }
            }
            .separador-item {
                width: 100%;
                position: relative;
                top: 30%;
                right: 36.5%;
                margin-bottom: 200px;
            }
        }
        .grid-2{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            margin-top:20px;
            .item-left{
                width: 60%;
                display: flex;
                justify-content: right;
                position: relative;
                padding-left: 8%;
            }
            .item-right{
                width: 40%;
                position: relative;
                display: flex;
                justify-content: center;
                .text{
                    z-index: 1;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    align-content: center;
                    color: #ffffff;
                    position: relative;
                    padding-right: 15%;
                    .title{
                        width: 100%;
                        font-size: 7rem;
                        margin: 20px 0;
                        color:#0097AA;
                        font-family: 'Bebas Neue', sans-serif;
                        text-align: end;
                        line-height: 90px;
                    }
                    .texto{
                        width: 100%;
                        font-size: 2rem;
                        text-align: end;
                        color:#0097AA;
                        font-family: 'Helvetica Medium', sans-serif;
                        margin-top: 20px;
                    }
                }
            }
            .separador-item {
                width: auto;
                position: relative;
                left: 86%;
                top: -5%;
                margin-bottom: 50px;
            }
        }
        .grid-3{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            .item-left{
                width: 40%;
                position: relative;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                .text{
                    z-index: 1;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    align-content: center;
                    color: #ffffff;
                    position: relative;
                    padding-left: 15%;
                    .title{
                        width: 100%;
                        font-size: 7rem;
                        margin: 20px 0;
                        color:#0097AA;
                        font-family: 'Bebas Neue', sans-serif;
                        text-align: start;
                        line-height: 90px;
                    }
                    .texto{
                        width: 100%;
                        font-size: 2rem;
                        text-align: start;
                        color:#0097AA;
                        font-family: 'Helvetica Medium', sans-serif;
                        margin-top: 20px;
                    }
                }
            }
            .item-right{
                width: 60%;
                position: relative;
                display: flex;
                justify-content: left;
                padding-right: 5%;
            }
        }
        .grid-4{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            margin-top: 150px;
            .item-left{
                width: 60%;
                position: relative;
                display: flex;
                flex-wrap: wrap;
                justify-content: right;
                padding-left: 8%;
            }
            .item-right{
                width: 40%;
                position: relative;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                .text{
                    z-index: 1;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    align-content: center;
                    color: #ffffff;
                    position: relative;
                    padding-right: 15%;
                    .title{
                        width: 100%;
                        font-size: 7rem;
                        margin: 20px 0;
                        color:#0097AA;
                        font-family: 'Bebas Neue', sans-serif;
                        text-align: end;
                        line-height: 90px;
                    }
                    .texto{
                        width: 100%;
                        font-size: 2rem;
                        text-align: end;
                        color:#0097AA;
                        font-family: 'Helvetica Medium', sans-serif;
                        margin-top: 20px;
                    }
                }
            }
            .separador-item {
                width: auto;
                position: relative;
                left: 60%;
                bottom: 80%;
                margin-bottom: 50px;
            }
        }
        .grid-5{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 100px;
            h2 {
                width: 100%;
                color: #0097AA;
                font-size: 5rem;
                font-family: 'Bebas Neue', sans-serif;
                text-align: center;
                font-weight: 100;
                margin-top: 0;
            }
            .item-left{
                width: 33.333333%;
                position: relative;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
            }
            .item-center{
                width: 33.333333%;
                position: relative;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

            }
            .item-right{
                width: 33.333333%;
                position: relative;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
            }
            picture {
                width: 100%;
            }
            .text {
                width: 100%;
                text-align: center;
                .title {
                    color: #0097AA;
                    font-size: 2.4rem;
                    font-weight: bold;
                    font-family: 'Helvetica Bold', sans-serif;
                    margin-bottom: 0;
                }
                .texto {
                    font-family: 'Helvetica Medium', sans-serif;
                    color: #0097AA;
                    font-size: 1.4rem;
                    margin-top: 10px;
                }
            }
        }
        .separador.footer{
            height: 450px;
            align-content: flex-start;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            color: #ffffff;
            background-color: #0097AA;
            font-family: 'Helvetica Heavy', sans-serif;
            p {
                font-size: 1.7rem;
                margin-bottom: 0;
                margin-top: 70px;
            }
            img{
                margin-top: 100px;
                width: 45%;
            }
        }
        .footer-app{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-content: flex-end;
            background-color: #ffffff;
            height: 150px;
            color: #333333;
            font-size: 1.2rem;
            position: relative;
            font-family: 'Helvetica Normal', sans-serif;
            .revi-widget{
                position: absolute;
                z-index: 1;
                width: 100%;
                max-width: 500px;
                top: -25%;

            }
            .revi_description a, a span, path{
                color: #db559c !important;
                fill: #db559c !important;
            }
        }
    }
    
    .loader{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        min-height: 100vh;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
        z-index: 99999;
        background-color: #0097AA;
        overflow-y: hidden;
        .intex{
            width: 100%;
            max-width: 500;
            display: flex;
            flex-wrap: wrap;
            justify-content:center;
            align-items: center;
            position: relative;
            .letra{
                width: 20%;
                max-width: 100px;
                animation: intex 1.7s infinite alternate;
                @for $i from 0 through 5 {
                  &:nth-child(#{$i+1}) {
                    animation-delay: #{$i*.1}s;
                  }
                }
            }
        }
        
    }
    
    @media screen and (max-width: 1600px) {
        .App{
            .grid{
                margin-top:80px;
                .item{
                    width: 50%;
                    .text{
                        top: 15%;
                        left: 15%;
                        .title{
                            font-size: 6rem;
                            margin: 20px 0;
                            line-height: 85px;
                        }
                        .texto{
                            font-size: 1.7rem;
                            margin-top: 15px;
                        }                    
                    }
                    .text-encuentra{           
                        .textoBlanco {
                            font-size: 1.7rem;
                            line-height: 40px;
                        }
                    }
                }
                .separador-item {
                    width: 100%;
                    position: relative;
                    top: 30%;
                    right: 38%;
                    margin-bottom: 180px;
                    img {
                        width: 130px;
                    }
                }
            }
            .grid-2{
                margin-top: 20px;
                .item-left{
                    padding-left: 8%;
                }
                .item-right{
                    .text{
                        padding-right: 15%;
                        .title{
                            font-size: 6rem;
                            margin: 20px 0;
                            line-height: 80px;
                        }
                        .texto{
                            font-size: 1.7rem;
                            margin-top: 15px;
                        }
                    }
                }
                
                .separador-item {
                    left: 86%;
                    margin-bottom: 50px;
                    img {
                        width: 130px;
                    }
                }
            }
            .grid-3{
                .item-left{
                    .text{
                        padding-left: 15%;
                        .title{
                            font-size: 6rem;
                            margin: 20px 0;
                            line-height: 80px;
                        }
                        .texto{
                            font-size: 1.7rem;
                            margin-top: 15px;
                        }
                    }
                }
                .item-right{
                    padding-right: 5%;
                }
            }
            .grid-4{
                margin-top: 150px;
                .item-left{
                    padding-left: 8%;
                }
                .item-right{
                    .text{
                        padding-right: 15%;
                        .title{
                            font-size: 6rem;
                            margin: 20px 0;
                            line-height: 80px;
                        }
                        .texto{
                            font-size: 1.7rem;
                            margin-top: 15px;
                        }
                    }
                }
                .separador-item {
                    left: 60%;
                    bottom: 80%;
                    margin-bottom: 50px;
                    img {
                        width: 130px;
                    }
                }
            }
            .separador.footer{
                height: 400px;
                p {
                    font-size: 1.7rem;
                    margin-bottom: 0;
                    margin-top: 70px;
                }
                img{
                    margin-top: 90px;
                    width: 40%;
                }
            }
            .footer-app{
                height: 180px;
                .revi-widget{
                    max-width: 400px;
                }
            }
        }
    }

    @media screen and (max-width: 1480px) {
        .App{
            .grid{
                margin-top:80px;
                .item{
                    width: 50%;
                    .text{
                        top: 20%;
                        left: 15%;
                        .title{
                            font-size: 5.5rem;
                            margin: 20px 0;
                            line-height: 80px;
                        }
                        .texto{
                            font-size: 1.6rem;
                            margin-top: 10px;
                        }                    
                    }
                    .text-encuentra{           
                        .textoBlanco {
                            font-size: 1.6rem;
                            line-height: 35px;
                        }
                    }
                }
                .separador-item {
                    width: 100%;
                    position: relative;
                    top: 30%;
                    right: 38%;
                    margin-bottom: 180px;
                    img {
                        width: 120px;
                    }
                }
            }
            .grid-2{
                margin-top: 20px;
                .item-left{
                    padding-left: 7%;
                }
                .item-right{
                    .text{
                        padding-right: 15%;
                        .title{
                            font-size: 5rem;
                            margin: 15px 0;
                            line-height: 70px;
                        }
                        .texto{
                            font-size: 1.6rem;
                            margin-top: 15px;
                        }
                    }
                }
                .separador-item {
                    left: 86%;
                    margin-bottom: 50px;
                    img {
                        width: 120px;
                    }
                }
            }
            .grid-3{
                .item-left{
                    .text{
                        padding-left: 15%;
                        .title{
                            font-size: 5rem;
                            margin: 15px 0;
                            line-height: 80px;
                        }
                        .texto{
                            font-size: 1.6rem;
                            margin-top: 15px;
                        }
                    }
                }
                .item-right{
                    padding-right: 5%;
                }
            }
            .grid-4{
                margin-top: 150px;
                .item-left{
                    padding-left: 7%;
                }
                .item-right{
                    .text{
                        padding-right: 15%;
                        .title{
                            font-size: 5rem;
                            margin: 15px 0;
                            line-height: 70px;
                        }
                        .texto{
                            font-size: 1.6rem;
                            margin-top: 15px;
                        }
                    }
                }
                .separador-item {
                    left: 60%;
                    bottom: 80%;
                    margin-bottom: 50px;
                    img {
                        width: 120px;
                    }
                }
            }
            .separador.footer{
                height: 350px;
                p {
                    font-size: 1.6rem;
                    margin-bottom: 0;
                    margin-top: 50px;
                }
                img{
                    margin-top: 80px;
                    width: 40%;
                }
            }
            .footer-app{
                height: 180px;
                .revi-widget{
                    max-width: 400px;
                }
            }
        }
    }
    
    @media screen and (max-width: 1280px) {
        .App{
            .grid{
                margin-top:80px;
                .item{
                    width: 50%;
                    .text{
                        top: 20%;
                        left: 12%;
                        .title{
                            font-size: 5rem;
                            margin: 15px 0;
                            line-height: 75px;
                        }
                        .texto{
                            font-size: 1.5rem;
                            margin-top: 10px;
                        }                    
                    }
                    .text-encuentra{           
                        .textoBlanco {
                            font-size: 1.5rem;
                            line-height: 33px;
                        }
                    }
                }
                .separador-item {
                    width: 100%;
                    position: relative;
                    top: 30%;
                    right: 40%;
                    margin-bottom: 160px;
                    img {
                        width: 110px;
                    }
                }
            }
            .grid-2{
                margin-top: 20px;
                .item-left{
                    padding-left: 5%;
                }
                .item-right{
                    .text{
                        padding-right: 15%;
                        .title{
                            font-size: 4.5rem;
                            margin: 10px 0;
                            line-height: 65px;
                        }
                        .texto{
                            font-size: 1.4rem;
                            margin-top: 15px;
                        }
                    }
                }
                .separador-item {
                    left: 84%;
                    margin-bottom: 50px;
                    img {
                        width: 110px;
                    }
                }
            }
            .grid-3{
                .item-left{
                    .text{
                        padding-left: 15%;
                        .title{
                            font-size: 4.5rem;
                            margin: 10px 0;
                            line-height: 65px;
                        }
                        .texto{
                            font-size: 1.4rem;
                            margin-top: 15px;
                        }
                    }
                }
                .item-right{
                    padding-right: 5%;
                }
            }
            .grid-4{
                margin-top: 150px;
                .item-left{
                    padding-left: 5%;
                }
                .item-right{
                    .text{
                        padding-right: 15%;
                        .title{
                            font-size: 4.5rem;
                            margin: 10px 0;
                            line-height: 65px;
                        }
                        .texto{
                            font-size: 1.4rem;
                            margin-top: 15px;
                        }
                    }
                }
                .separador-item {
                    left: 60%;
                    bottom: 80%;
                    margin-bottom: 50px;
                    img {
                        width: 110px;
                    }
                }
            }
            .grid-5{
                margin-bottom: 80px;
                h2 {
                    font-size: 4rem;
                }
                .text {
                    .title {
                        font-size: 2rem;
                    }
                    .texto {
                        font-size: 1.1rem;
                        margin-top: 10px;
                    }
                }
            }
            .separador.footer{
                height: 300px;
                p {
                    font-size: 1.5rem;
                    margin-bottom: 0;
                    margin-top: 40px;
                }
                img{
                    margin-top: 60px;
                    width: 40%;
                }
            }
            .footer-app{
                height: 180px;
                .revi-widget{
                    max-width: 400px;
                }
            }
        }
    }
    
    @media screen and (max-width: 1080px) {
        .App{
            .grid{
                margin-top:70px;
                .item{
                    width: 50%;
                    .text{
                        top: 20%;
                        left: 10%;
                        .title{
                            font-size: 4.5rem;
                            margin: 12px 0;
                            line-height: 70px;
                        }
                        .texto{
                            font-size: 1.4rem;
                            margin-top: 10px;
                        }                    
                    }
                    .text-encuentra{           
                        .textoBlanco {
                            font-size: 1.4rem;
                            line-height: 30px;
                        }
                    }
                }
                .separador-item {
                    width: 100%;
                    position: relative;
                    top: 35%;
                    right: 40%;
                    margin-bottom: 160px;
                    img {
                        width: 100px;
                    }
                }
            }
            .grid-2{
                margin-top: 20px;
                .item-left{
                    padding-left: 5%;
                }
                .item-right{
                    .text{
                        padding-right: 15%;
                        .title{
                            font-size: 4rem;
                            margin: 10px 0;
                            line-height: 60px;
                        }
                        .texto{
                            font-size: 1.3rem;
                            margin-top: 10px;
                        }
                    }
                }
                
                .separador-item {
                    left: 84%;
                    margin-bottom: 50px;
                    img {
                        width: 100px;
                    }
                }
            }
            .grid-3{
                .item-left{
                    .text{
                        padding-left: 15%;
                        .title{
                            font-size: 4rem;
                            margin: 10px 0;
                            line-height: 60px;
                        }
                        .texto{
                            font-size: 1.3rem;
                            margin-top: 10px;
                        }
                    }
                }
                .item-right{
                    padding-right: 5%;
                }
            }
            .grid-4{
                margin-top: 130px;
                .item-left{
                    padding-left: 5%;
                }
                .item-right{
                    .text{
                        padding-right: 15%;
                        .title{
                            font-size: 4rem;
                            margin: 10px 0;
                            line-height: 60px;
                        }
                        .texto{
                            font-size: 1.3rem;
                            margin-top: 10px;
                        }
                    }
                }
                .separador-item {
                    left: 60%;
                    bottom: 80%;
                    margin-bottom: 50px;
                    img {
                        width: 100px;
                    }
                }
            }
            .grid-5{
                margin-bottom: 60px;
                h2 {
                    font-size: 3.5rem;
                }
            }
            .separador.footer{
                height: 250px;
                p {
                    font-size: 1.4rem;
                    margin-bottom: 0;
                    margin-top: 30px;
                }
                img{
                    margin-top: 50px;
                    width: 40%;
                }
            }
            .footer-app{
                height: 150px;
                font-size: 1.2rem;
                .revi-widget{
                    max-width: 350px;
                }
            }
        }
    }
    
    @media screen and (max-width: 940px) {
        .App{
            .grid{
                margin-top:60px;
                .item{
                    width: 50%;
                    .text{
                        top: 20%;
                        left: 10%;
                        .title{
                            font-size: 3rem;
                            margin: 0;
                            line-height: 40px;
                        }
                        .texto{
                            font-size: 1.2rem;
                            margin-top: 5px;
                        }                    
                    }
                    .text-encuentra{     
                        padding-left: 13%;      
                        .textoBlanco {
                            font-size: 0.9rem;
                            font-weight: bold;
                            line-height: 20px;
                        }
                    }
                }
                .separador-item {
                    width: 100%;
                    position: relative;
                    top: 25%;
                    right: 40%;
                    margin-bottom: 70px;
                    img {
                        width: 80px;
                    }
                }
            }
            .grid-2{
                margin-top: 20px;
                .item-left{
                    padding-left: 4%;
                }
                .item-right{
                    .text{
                        padding-right: 10%;
                        .title{
                            font-size: 2.5rem;
                            margin: 10px 0;
                            line-height: 35px;
                        }
                        .texto{
                            font-size: 0.8rem;
                            margin-top: 5px;
                        }
                    }
                }
                .separador-item {
                    left: 85%;
                    margin-bottom: 40px;
                    img {
                        width: 80px;
                    }
                }
            }
            .grid-3{
                .item-left{
                    .text{
                        padding-left: 10%;
                        .title{
                            font-size: 2.5rem;
                            margin: 10px 0;
                            line-height: 35px;
                        }
                        .texto{
                            font-size: 0.8rem;
                            margin-top: 5px;
                        }
                    }
                }
                .item-right{
                    padding-right: 3%;
                }
            }
            .grid-4{
                margin-top: 110px;
                .item-left{
                    padding-left: 4%;
                }
                .item-right{
                    .text{
                        padding-right: 10%;
                        .title{
                            font-size: 2.5rem;
                            margin: 10px 0;
                            line-height: 35px;
                        }
                        .texto{
                            font-size: 0.8rem;
                            margin-top: 5px;
                        }
                    }
                }
                .separador-item {
                    left: 60%;
                    bottom: 80%;
                    margin-bottom: 40px;
                    img {
                        width: 80px;
                    }
                }
            }
            .grid-5{
                margin-bottom: 30px;
                h2 {
                    font-size: 3rem;
                    margin-bottom: 20px;
                }
                .text {
                    .title {
                        font-size: 1.5rem;
                    }
                    .texto {
                        font-size: 0.7rem;
                        margin-top: 5px;
                    }
                }
            }
            .separador.footer{
                height: 250px;
                p {
                    font-size: 1.3rem;
                    margin-bottom: 0;
                    margin-top: 20px;
                }
                img{
                    margin-top: 40px;
                    width: 55%;
                }
            }
            .footer-app{
                height: 180px;
                .revi-widget{
                    max-width: 100%;
                }
            }
        }
    }
    
    @media screen and (max-width: 600px){
        .App{
            .grid{
                margin-top:40px;
                .item{
                    width: 50%;
                    .text{
                        top: 20%;
                        left: 10%;
                        .title{
                            font-size: 2rem;
                            margin: 0;
                            line-height: 30px;
                        }
                        .texto{
                            font-size: 0.7rem;
                            margin-top: 0px;
                        }                    
                    }
                    .text-encuentra{     
                        padding-left: 13%;      
                        .textoBlanco {
                            font-size: 0.6rem;
                            font-weight: bold;
                            line-height: 13px;
                        }
                    }
                }
                .separador-item {
                    width: 100%;
                    position: relative;
                    top: 30%;
                    right: 40%;
                    margin-bottom: 60px;
                    img {
                        width: 50px;
                    }
                }
            }
            .grid-2{
                margin-top: 20px;
                .item-left{
                    padding-left: 3%;
                }
                .item-right{
                    .text{
                        padding-right: 7%;
                        .title{
                            font-size: 2rem;
                            margin: 10px 0;
                            line-height: 30px;
                        }
                        .texto{
                            font-size: 0.6rem;
                            margin-top: 2px;
                        }
                    }
                }
                
                .separador-item {
                    left: 86%;
                    margin-bottom: 25px;
                    img {
                        width: 50px;
                    }
                }
            }
            .grid-3{
                .item-left{
                    .text{
                        padding-left: 13%;
                        .title{
                            font-size: 2rem;
                            margin: 10px 0;
                            line-height: 30px;
                        }
                        .texto{
                            font-size: 0.6rem;
                            margin-top: 2px;
                        }
                    }
                }
                .item-right{
                    padding-right: 2%;
                }
            }
            .grid-4{
                margin-top: 70px;
                .item-left{
                    padding-left: 4%;
                }
                .item-right{
                    .text{
                        padding-right: 10%;
                        .title{
                            font-size: 2rem;
                            margin: 10px 0;
                            line-height: 30px;
                        }
                        .texto{
                            font-size: 0.6rem;
                            margin-top: 2px;
                        }
                    }
                }
                .separador-item {
                    left: 60%;
                    bottom: 80%;
                    margin-bottom: 25px;
                    img {
                        width: 50px;
                    }
                }
            }
            .grid-5{
                margin-bottom: 20px;
                h2 {
                    font-size: 2rem;
                    margin-bottom: 15px;
                }
                .text {
                    .title {
                        font-size: 1.1rem;
                        margin-top: 10px;
                    }
                    .texto {
                        font-size: 0.5rem;
                        margin-top: 5px;
                    }
                }
            }
            .separador.footer{
                height: 200px;
                p {
                    font-size: 1.3rem;
                    margin-bottom: 0;
                    margin-top: 20px;
                }
                img{
                    margin-top: 20px;
                    width: 60%;
                }
            }
            .footer-app{
                height: 180px;
                font-size: 1rem;
                .revi-widget{
                    max-width: 100%;
                }
            }
        }
    }
    
    @media screen and (max-width: 420px){
        .App{
            .grid{
                margin-top:40px;
                .item{
                    width: 50%;
                    .text{
                        top: 20%;
                        left: 10%;
                        .title{
                            font-size: 1.3rem;
                            margin: 0;
                            line-height: 18px;
                        }
                        .texto{
                            font-size: 0.6rem;
                            margin-top: 5px;
                        }                    
                    }
                    .text-encuentra{     
                    padding-left: 14%;      
                        .textoBlanco {
                            font-size: 0.5rem;
                            font-weight: bold;
                            line-height: 10px;
                        }
                    }
                }
                .separador-item {
                    position: relative;
                    top: 30%;
                    right: 42%;
                    margin-bottom: 30px;
                    img {
                        width: 25px;
                    }
                }
            }
            .grid-2{
                margin-top: 20px;
                .item-left{
                    padding-left: 4%;
                    width: 50%;
                }
                .item-right{
                    width: 50%;
                    .text{
                        padding-right: 7%;
                        .title{
                            font-size: 1.5rem;
                            margin: 5px 0;
                            line-height: 20px;
                        }
                        .texto{
                            font-size: 0.5rem;
                            margin-top: 0;
                        }
                    }
                }
                .separador-item {
                    left: 89%;
                    margin-bottom: 15px;
                    img {
                        width: 25px;
                    }
                }
            }
            .grid-3{
                .item-left{
                    width: 50%;
                    .text{
                        padding-left: 13%;
                        .title{
                            font-size: 1.5rem;
                            margin: 5px 0;
                            line-height: 20px;
                        }
                        .texto{
                            font-size: 0.5rem;
                            margin-top: 0;
                        }
                    }
                }
                .item-right{
                    width: 50%;
                    padding-right: 2%;
                }
            }
            .grid-4{
                margin-top: 40px;
                .item-left{
                    padding-left: 4%;
                    width: 50%;
                }
                .item-right{
                    width: 50%;
                    .text{
                        padding-right: 10%;
                        .title{
                            font-size: 1.5rem;
                            margin: 5px 0;
                            line-height: 20px;
                        }
                        .texto{
                            font-size: 0.6rem;
                            margin-top: 0;
                        }
                    }
                }
                .separador-item {
                    left: 50%;
                    bottom: 80%;
                    margin-bottom: 15px;
                    img {
                        width: 25px;
                    }
                }
            }
            .grid-5{
                margin-bottom: 20px;
                h2 {
                    font-size: 1.5rem;
                    margin-bottom: 10px;
                }
                .text {
                    .title {
                        font-size: 0.9rem;
                        margin-top: 5px;
                    }
                    .texto {
                        font-size: 0.42rem;
                        margin-top: 5px;
                    }
                }
            }
            .separador.footer{
                height: 180px;
                p {
                    font-size: 1.1rem;
                    margin-bottom: 0;
                    margin-top: 20px;
                }
                img{
                    margin-top: 20px;
                    width: 70%;
                }
            }
            .footer-app{
                height: 180px;
                .revi-widget{
                    max-width: 100%;
                }
            }
        }
    }